/*
 * Specific styles of signin component
 */
/*
 * General styles
 */

.login-container {
    position: relative;
    background-repeat: no-repeat;
    background-image: url(../../content/images/mqalogin.jpg);
    background-position: center;
    background-size: cover;
    text-align: center;
    color: #fff;
    height: 100vh;
    width: 100%;
    overflow-y: hidden !important;
}

.login-form{
margin-top:50px;
}
 .log-msg-none{
 display:none
 }
 .log-msg{
 display:block;
 }
.card-container.sec-card {
    /*max-width: 360px;
    padding: 5px 40px 40px 40px;  
    margin-top: 50px*/
    width: 360px;
    height: 360px;
    top: 35%;
    left: 75%;
    margin-top: -200px;
    margin-left: -180px;
    position: absolute;
}

/*
 * Card component
 */
.sec-card{
    //background-color: #F7F7F7;
    /* just in case there no content*/
    padding: 0 25px 30px;
    margin: 0 auto 25px;
    margin-top: 0px;
    margin-bottom: 50px;
    /* shadows and rounded borders */
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    /*-moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);*/
}

.profile-img-card {
    width: 96px;
    height: 96px;
    margin: 0 auto 10px;
    display: block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

/*
 * Form styles
 */
.profile-name-card {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin: 10px 0 0;
    min-height: 1em;
}

.reauth-email {
    display: block;
    color: #404040;
    line-height: 2;
    margin-bottom: 10px;
    font-size: 14px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.form-signin .inputEmail,
.form-signin .inputPassword {
    direction: ltr;
    height: 52px;
    font-size: 16px;
}

.form-signin input[type=email],
.form-signin input[type=password],
.form-signin input[type=text],
.form-signin {
    width: 100%;
    display: block;
    margin-bottom: 10px;
    z-index: 1;
    position: relative;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

    .form-signin .form-control:focus {
        border-color: rgb(104, 145, 162);
        outline: 0;
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgb(104, 145, 162);
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgb(104, 145, 162);
    }

.btn.btn-signin button{
    
    padding: 0px;
    font-weight: 700;
    font-size: 14px;
    height: 50px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    border: none;
    -o-transition: all 0.218s;
    -moz-transition: all 0.218s;
    -webkit-transition: all 0.218s;
    transition: all 0.218s;
}

.btn.btn-signin:hover,
.btn.btn-signin:active,
.btn.btn-signin:focus {
    
}

.loginButton {
    /*background-image: linear-gradient(to right, #00546f 0%, #60f4ff 100%);*/
    background-color: #387c2b;
    background-repeat: no-repeat;
    background-size: auto;
    border: none;
    margin-bottom: 10px;
}

.login-style {
    color: green;
    text-align: left;
}

.ForgotPasswordButton {
}

.loginCopyright {
    top: 94%;
    left: 75%;
    color: black;
    margin-left: -205px;
    position: absolute;
}

.forgot-password {
    color: rgb(104, 145, 162);
}

    .forgot-password:hover,
    .forgot-password:active,
    .forgot-password:focus {
        color: rgb(12, 97, 33);
    }
.img-responsive {
    height: 200px;
    width: 320px;
    margin-top: 0px;
    margin-bottom: 30px;
}

.img-foodbev-responsive {
    height: 220px;
    width: 210px;
    margin-top: 0px;
    margin-bottom: 30px;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    body {
      padding:0px 0px 0px;       
    }

    .img-responsive {
        //width: 50%;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    /*.card-container.sec-card {
        margin: 0px 0px 0px;
    }*/
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .img-responsive {
        margin-top: 50px;
        margin-bottom: 50px;
    }
}

