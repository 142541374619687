
.sdf-container {
    position: relative;
    background-repeat: no-repeat;
    background-image: url(../../../content/images/mqalogin.jpg);
    background-position: center;
    background-size: cover;
    text-align: center;
    color: #fff;
    height: 100vh;
    width: 100%;
    overflow-y: hidden !important;
}


.btn.btn-register button{
    
    padding: 0px;
    font-weight: 700;
    font-size: 14px;
    height: 50px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    border: none;
    -o-transition: all 0.218s;
    -moz-transition: all 0.218s;
    -webkit-transition: all 0.218s;
    transition: all 0.218s;
}

.submitButton {
    background-image:linear-gradient(to right, #00546f 0%, #60f4ff 100%);
    background-repeat: no-repeat;
    background-size: auto;
    border: none;
}
