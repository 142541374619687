@media all and (min-width: 480px) {

    .tabContainer {
        margin-top: 32px;
    }

    .agentForm {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }
    .agentFormTabPanel {
        width: 100%;
        height: 38vh;
        overflow-y: auto;
    }
}
